import { Api } from "@/models/class/api.class";
import { ResponseListStockCardReport } from "@/models/interface-v2/stock-card.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class StockCardService extends HttpClient {
  public constructor() {
    super();

    this.getListStockCard = this.getListStockCard.bind(this);
    this.downloadStockCard = this.downloadStockCard.bind(this);
    this.printStockCard = this.printStockCard.bind(this);
  }

  getListStockCard(
    params: RequestQueryParamsModel
  ): Promise<ResponseListStockCardReport> {
    return this.get<ResponseListStockCardReport>(Api.ReportStockCard, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadStockCard(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportStockCard + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printStockCard(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportStockCard + `/print`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const stockCardService = new StockCardService();
