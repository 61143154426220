








































































































































































































































































































































// core
import Vue from "vue";
import moment from "moment";
import printJS from "print-js";
// services
import { productService } from "@/services-v2/product.service";
import { stockCardService } from "@/services-v2/stock-card.service";
import { warehouseService } from "@/services-v2/warehouse.service";
import { warehouseLocationService } from "@/services-v2/warehouse-location.service";
import { warehouseBranchService } from "@/services-v2/warehouse-branch.service";
import { productCategoryServices } from "@/services-v2/product-category.service";
import { masterUomServices } from "@/services-v2/master-uom.service";
import { assetsServices } from "@/services/assets.service";
// interface
import {
  ResponseBranchWarehouse,
  ResponseListBranchWarehouse,
  ResponseListWarehouseLocation,
  ResponseWarehouseLocation,
  WarehouseResponse,
} from "@/models/interface-v2/warehouse.interface";
import {
  ResponseListProduct,
  ResponseProduct,
} from "@/models/interface-v2/product.interface";
import {
  ResponseListStockCardReport,
  ResponseStockCardReport,
} from "@/models/interface-v2/stock-card.interface";
import {
  ResponseListProductCategory,
  ResponseProductCategory,
} from "@/models/interface-v2/product-category.interface";
import {
  ResponseListProductUom,
  ResponseProductUom,
} from "@/models/interface-v2/uom.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
// constant
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
// utility
import { debounceProcess } from "@/helpers/debounce";
import { formatDecimalQty } from "@/helpers/common";
import { FormModel } from "ant-design-vue";

export default Vue.extend({
  name: "StockCard",
  components: {
    CSelectBatchNumber: () =>
      import(
        /*webpackPrefetch:true */ "@/components/shared/select-batch-number/CSelectBatchNumber.vue"
      ),
  },
  data() {
    this.getProductName = debounceProcess(this.getProductName, 200);
    this.getProductCategory = debounceProcess(this.getProductCategory, 200);
    this.getListLocationBranch = debounceProcess(
      this.getListLocationBranch,
      200
    );
    this.getListLocationRack = debounceProcess(this.getListLocationRack, 200);
    this.getListWarehouse = debounceProcess(this.getListWarehouse, 200);
    return {
      DEFAULT_DATE_FORMAT,
      formModel: {
        productCategory: "",
        productName: "",
        uom: "",
        branch: "",
        warehouse: "",
        rack: "",
        date: [],
        batchNumber: undefined as string | undefined,
      },
      formModal: this.$form.createForm(this, { name: "kartuStockModal" }),
      totalData: 0 as number,
      dataProductName: [] as ResponseProduct[],
      dataProductCategory: [] as ResponseProductCategory[],
      dataUom: [] as ResponseProductUom[],
      dataListBranch: [] as ResponseBranchWarehouse[],
      dataListWarehouse: [] as WarehouseResponse[],
      dataListLocationRack: [] as ResponseWarehouseLocation[],
      idBranch: "" as string,
      idWarehouse: "" as string,
      loadingProductCode: false as boolean,
      loadingLocationWarehouse: false as boolean,
      loadingLocationBranch: false as boolean,
      loadingFind: false as boolean,
      loadingDownload: false as boolean,
      loadingPrint: false as boolean,
      dtListStockCardReport: [] as ResponseStockCardReport[],
      modalVisible: false as boolean,
      columnsTable: [
        {
          title: this.$t("lbl_date"),
          dataIndex: "date",
          key: "date",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_document_reference"),
          dataIndex: "documentReference",
          key: "documentReference",
          // width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_related_document"),
          dataIndex: "documentRelated",
          key: "documentRelated",
          // width: 180,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_batch_number"),
          dataIndex: "batchNumber",
          key: "batchNumber",
          // width: 180,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_customer_supplier_name"),
          dataIndex: "customerSupplierName",
          key: "customerSupplierName",
          // width: 250,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "location",
          key: "location",
          // width: 350,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_initial_qty"),
          dataIndex: "onHandBefore",
          key: "onHandBefore",
          // width: 150,
          scopedSlots: { customRender: "onHandBefore" },
        },
        {
          title: this.$t("lbl_qty_in"),
          dataIndex: "qtyIn",
          key: "qtyIn",
          // width: 150,
          scopedSlots: { customRender: "qty" },
        },
        {
          title: this.$t("lbl_qty_out"),
          dataIndex: "qtyOut",
          key: "qtyOut",
          // width: 150,
          scopedSlots: { customRender: "qty" },
        },
        {
          title: this.$t("lbl_qty_balance"),
          dataIndex: "onHandAfter",
          key: "onHandAfter",
          // width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_note"),
          dataIndex: "notes",
          key: "notes",
          // width: 250,
          scopedSlots: { customRender: "isNull" },
        },
      ],
      formRulesModal: {
        merk: {
          label: "Merk",
          name: "merk",
          placeholder: "Insert Merk",
          decorator: ["merk"],
        },
        type: {
          label: "Type",
          name: "type",
          placeholder: "Insert Type",
          decorator: ["type"],
        },
        serialNumber: {
          label: "Serial Number",
          name: "serialNumber",
          placeholder: "Insert Serial Number",
          decorator: ["serialNumber"],
        },
      },
      formRules: {
        productCategory: {
          label: "lbl_product_category",
          name: "productCategory",
          placeholder: "lbl_choose",
        },
        productName: {
          label: "lbl_product_name",
          name: "productName",
          placeholder: "lbl_choose",
        },
        uom: {
          label: "lbl_uom",
          name: "uom",
          placeholder: "lbl_choose",
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_choose",
        },
        warehouse: {
          label: "lbl_warehouse",
          name: "warehouse",
          placeholder: "lbl_choose",
        },
        rack: {
          label: "lbl_rack",
          name: "rack",
          placeholder: "lbl_choose",
        },
        date: {
          label: "lbl_date",
          name: "date",
          placeholder: "lbl_choose",
        },
        batchNumber: {
          label: "lbl_batch_number",
          name: "batch number",
          placeholder: "lbl_choose",
        },
      },
      rules: {
        date: [
          {
            required: true,
            message: (): string =>
              this.$t("lbl_validation_required_error").toString(),
          },
          {
            validator: this.validateDate,
            trigger: "change",
            message: (): string =>
              this.$t("lbl_error_date_range_max_x_year", { x: 1 }).toString(),
          },
        ],
      },
      paramReports: {
        limit: 10,
        page: 0,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    this.getProductName("");
    this.getProductCategory("");
    this.getListLocationBranch("");
    this.getListOfBaseUnit("");
    this.fillTable();
  },
  methods: {
    moment,
    handleOk() {
      this.modalVisible = false;
      this.formModal.resetFields();
    },
    handleClickedColumn(record, objColumnNameValue) {
      this.modalVisible = true;
      if (objColumnNameValue && objColumnNameValue.value) {
        let params: RequestQueryParamsModel = {
          limit: 10,
          page: 0,
          search: `unitCode~${objColumnNameValue.value}`,
        };
        assetsServices.listMasterAsset(params).then((response) => {
          if (response.data.length > 0) {
            this.formModal.setFieldsValue({
              merk: response.data[0].assetCategory.segments[1].first,
              type: response.data[0].type,
              serialNumber: response.data[0].serialNumber,
            });
          } else {
            this.$message.error("Data Master Not Found");
          }
        });
      }
    },
    handleChangeLocationBranch(id) {
      this.idBranch = id;
      this.formModel.warehouse = "";
      this.formModel.rack = "";
      this.getListWarehouse("", this.idBranch);
    },
    handleChangeWarehouse(id) {
      this.idWarehouse = id;
      this.getListLocationRack("", id);
    },
    getListWarehouse(valueSearch, id) {
      if (id) {
        let params = {
          page: 0,
          limit: 10,
          search: `branchWarehouse.secureId~${id}`,
        } as RequestQueryParamsModel;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationWarehouse = true;
        warehouseService
          .listWarehouse(params)
          .then((data) => {
            this.dataListWarehouse = data.data;
          })
          .finally(() => (this.loadingLocationWarehouse = false));
      } else {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("notif_error_choose_location_branch").toString(),
        });
      }
    },
    getListLocationRack(valueSearch, id) {
      if (id) {
        let params = {
          page: 0,
          limit: 10,
          search: `warehouse.secureId~${id}`,
        } as RequestQueryParamsModel;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationBranch = true;
        warehouseLocationService
          .listWarehouseLocation(params)
          .then((data: ResponseListWarehouseLocation) => {
            this.dataListLocationRack = data.data;
          })
          .finally(() => (this.loadingLocationBranch = false));
      } else {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("notif_error_choose_rack").toString(),
        });
      }
    },
    getListLocationBranch(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingLocationBranch = true;
      warehouseBranchService
        .listWarehouseBranch(params)
        .then((data: ResponseListBranchWarehouse) => {
          this.dataListBranch = data.data;
        })
        .finally(() => (this.loadingLocationBranch = false));
    },
    getProductCategory(valueSearch) {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        sorts: "createdDate:desc",
      };
      if (valueSearch) params.search = `name~*${valueSearch}*`;
      productCategoryServices
        .listProductCategory(params)
        .then((response: ResponseListProductCategory) => {
          response.data.forEach((data, index) => {
            data["key"] = index;
          });
          this.dataProductCategory = response.data;
        });
    },
    getListOfBaseUnit(valueSearch): void {
      let params = {
        // baseunit tidak dibutuhkan lagi untuk di stock adjustment - mas ucup
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `name~*${valueSearch}*`;
      masterUomServices
        .listMasterUom(params)
        .then((res: ResponseListProductUom) => {
          res.data.forEach((item, index) => (item["key"] = index));
          this.dataUom = res.data;
        });
    },
    handleInput(value, key, objectColInput) {
      this.dtListStockCardReport[key][objectColInput.name] = value;
      this.dtListStockCardReport = this.dtListStockCardReport.slice();
    },
    getProductName(valueSearch): void {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `name~*${valueSearch}*`;

      productService.listProduct(params).then((data: ResponseListProduct) => {
        this.dataProductName = data.data;
      });
    },
    buildDownloadParams(): RequestQueryParamsModel {
      const form = { ...this.formModel };
      const searchBy: string[] = [];
      if (form.productCategory)
        searchBy.push(`categoryId~${form.productCategory}`);
      if (form.productName) searchBy.push(`productName~${form.productName}`);
      if (form.uom) searchBy.push(`uomId~${form.uom}`);
      if (form.date.length) {
        const [start, end] = this.handleDateParam(form.date);
        searchBy.push(`date>=${start}_AND_date<=${end}`);
      }
      if (form.branch) searchBy.push(`branchId~${form.branch}`);
      if (form.warehouse) searchBy.push(`warehouseId~${form.warehouse}`);
      if (form.rack) searchBy.push(`locationId~${form.rack}`);
      if (form.batchNumber) searchBy.push(`batchId~${form.batchNumber}`);
      return {
        page: 0,
        limit: this.totalData,
        sorts: this.paramReports.sorts,
        search: searchBy.join("_AND_"),
      };
    },
    handleDownload(): void {
      this.loadingDownload = true;
      const params = this.buildDownloadParams();
      stockCardService
        .downloadStockCard(params)
        .then((response) => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "stock_report.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loadingDownload = false));
    },
    async handlePrint(): Promise<void> {
      try {
        const params = this.buildDownloadParams();
        this.loadingPrint = true;
        const res = await this.getListStockCard(params);
        const properties = [
          "date",
          "documentReference",
          "documentRelated",
          "customerSupplierName",
          "location",
          "onHandBefore",
          "onHandAfter",
          "qtyIn",
          "qtyOut",
          "notes",
        ];
        printJS({
          printable: res.data.map((item) => ({
            ...item,
            documentRelated: item.documentRelated ?? "",
            customerSupplierName: item.customerSupplierName ?? "",
            notes: item.notes ?? "",
          })),
          properties,
          type: "json",
          gridHeaderStyle: "border: 1px solid #000",
          gridStyle: "text-align: center;border: 1px solid #000",
          onError: (error) => {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: error.message,
            });
          },
        });
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loadingPrint = false;
      }
    },
    responsePageSizeChange(response: ResponsePagination) {
      this.paramReports.limit = response.size;
      this.paramReports.page = 0;
      this.fillTable();
    },
    responseCurrentPageChange(response: ResponsePagination) {
      this.paramReports.page = response.page - 1;
      this.fillTable();
    },
    getListStockCard(
      params: RequestQueryParamsModel
    ): Promise<ResponseListStockCardReport> {
      return stockCardService.getListStockCard(params);
    },
    async fillTable(): Promise<void> {
      try {
        this.loadingFind = true;
        const res: ResponseListStockCardReport = await stockCardService.getListStockCard(
          this.paramReports
        );
        this.totalData = res.totalElements;
        res.data.forEach((el, i) => {
          el["key"] = i.toString();
          el.date = this.moment(el.date).format(DEFAULT_DATE_FORMAT);
          el.onHandBefore = formatDecimalQty(el.onHandBefore) as number;
          el.onHandAfter = formatDecimalQty(el.onHandAfter) as number;
        });
        this.dtListStockCardReport = res.data;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loadingFind = false;
      }
    },
    handleDateParam(dateRange: string[] = []): string[] {
      const start = this.moment(dateRange[0])
        .set({ hour: 0, minute: 0, second: 0 })
        .utcOffset("+07")
        .format();
      const end = this.moment(dateRange[1])
        .set({ hour: 23, minute: 59, second: 59 })
        .utcOffset("+07")
        .format();
      return [start, end];
    },
    validateDate(rule, value, callback): void {
      if (
        this.formModel &&
        this.formModel.date &&
        this.formModel.date.length === 2
      ) {
        const start = this.moment(this.formModel.date[0]);
        const end = this.moment(this.formModel.date[1]);

        const diff = end.diff(start, "years", true);
        if (diff > 1) {
          callback(new Error());
        }

        callback();
      } else {
        callback(new Error());
      }
    },
    submitForm(): void {
      const formStock = this.$refs.formStock as FormModel;
      formStock.validate((valid, obj) => {
        if (!valid) {
          return;
        }

        const form = { ...this.formModel };
        const searchBy: string[] = [];
        if (form.productCategory)
          searchBy.push(`categoryId~${form.productCategory}`);
        if (form.productName) searchBy.push(`productName~${form.productName}`);
        if (form.uom) searchBy.push(`uomId~${form.uom}`);
        if (form.date.length) {
          const [start, end] = this.handleDateParam(form.date);
          searchBy.push(`date>=${start}_AND_date<=${end}`);
        }
        if (form.branch) searchBy.push(`branchId~${form.branch}`);
        if (form.warehouse) searchBy.push(`warehouseId~${form.warehouse}`);
        if (form.rack) searchBy.push(`locationId~${form.rack}`);
        if (form.batchNumber) searchBy.push(`batchId~${form.batchNumber}`);
        this.paramReports.search = searchBy.join("_AND_");
        this.fillTable();
      });
    },
    handleClear(): void {
      this.formModel = {
        productCategory: "",
        productName: "",
        uom: "",
        branch: "",
        warehouse: "",
        rack: "",
        date: [],
        batchNumber: "",
      };
    },
  },
});
