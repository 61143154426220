var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_stock_card") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-form-model",
                    _vm._b(
                      {
                        ref: "formStock",
                        attrs: { model: _vm.formModel, "label-align": "left" },
                        nativeOn: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submitForm.apply(null, arguments)
                          }
                        }
                      },
                      "a-form-model",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.productCategory.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        name:
                                          _vm.formRules.productCategory.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.productCategory
                                            .placeholder
                                        ),
                                        "show-search": true,
                                        "filter-option": false,
                                        loading: _vm.loadingProductCode,
                                        "allow-clear": true
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getProductCategory(value)
                                        }
                                      },
                                      model: {
                                        value: _vm.formModel.productCategory,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "productCategory",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.productCategory"
                                      }
                                    },
                                    _vm._l(_vm.dataProductCategory, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.name) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.productName.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        name: _vm.formRules.productName.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.productName.placeholder
                                        ),
                                        "show-search": true,
                                        "filter-option": false,
                                        loading: _vm.loadingProductCode,
                                        "allow-clear": true
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getProductName(value)
                                        }
                                      },
                                      model: {
                                        value: _vm.formModel.productName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "productName",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.productName"
                                      }
                                    },
                                    _vm._l(_vm.dataProductName, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.name }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.name) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.uom.label)
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        name: _vm.formRules.uom.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.uom.placeholder
                                        ),
                                        "show-search": false,
                                        "filter-option": false,
                                        loading: _vm.loadingProductCode,
                                        "allow-clear": true
                                      },
                                      on: {
                                        search: function(val) {
                                          return _vm.getListOfBaseUnit(val)
                                        }
                                      },
                                      model: {
                                        value: _vm.formModel.uom,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formModel, "uom", $$v)
                                        },
                                        expression: "formModel.uom"
                                      }
                                    },
                                    _vm._l(_vm.dataUom, function(data, index) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.unit) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.unit) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.date.label)
                                  }
                                },
                                [
                                  _c("a-range-picker", {
                                    attrs: {
                                      placeholder: [
                                        _vm.$t(_vm.formRules.date.placeholder),
                                        _vm.$t(_vm.formRules.date.placeholder)
                                      ],
                                      format: _vm.DEFAULT_DATE_FORMAT,
                                      type: "date"
                                    },
                                    model: {
                                      value: _vm.formModel.date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "date", $$v)
                                      },
                                      expression: "formModel.date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.branch.label)
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        name: _vm.formRules.branch.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.branch.placeholder
                                        ),
                                        "show-search": true,
                                        "filter-option": false,
                                        loading: _vm.loadingLocationBranch,
                                        "allow-clear": true
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getListLocationBranch(
                                            value
                                          )
                                        },
                                        change: _vm.handleChangeLocationBranch
                                      },
                                      model: {
                                        value: _vm.formModel.branch,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formModel, "branch", $$v)
                                        },
                                        expression: "formModel.branch"
                                      }
                                    },
                                    _vm._l(_vm.dataListBranch, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.name +
                                                          "-" +
                                                          data.address
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.name +
                                                      "-" +
                                                      data.address
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.warehouse.label)
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        name: _vm.formRules.warehouse.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.warehouse.placeholder
                                        ),
                                        "show-search": true,
                                        "filter-option": false,
                                        loading: _vm.loadingLocationWarehouse,
                                        "allow-clear": true
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getListWarehouse(
                                            value,
                                            _vm.idBranch
                                          )
                                        },
                                        change: _vm.handleChangeWarehouse
                                      },
                                      model: {
                                        value: _vm.formModel.warehouse,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "warehouse",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.warehouse"
                                      }
                                    },
                                    _vm._l(_vm.dataListWarehouse, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.name) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.rack.label)
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        name: _vm.formRules.rack.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.rack.placeholder
                                        ),
                                        "show-search": true,
                                        "filter-option": false,
                                        loading: _vm.loadingProductCode,
                                        "allow-clear": true
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getListLocationRack(
                                            value,
                                            _vm.idWarehouse
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.formModel.rack,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formModel, "rack", $$v)
                                        },
                                        expression: "formModel.rack"
                                      }
                                    },
                                    _vm._l(_vm.dataListLocationRack, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.name) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.batchNumber.label
                                    )
                                  }
                                },
                                [
                                  _c("CSelectBatchNumber", {
                                    model: {
                                      value: _vm.formModel.batchNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "batchNumber",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.batchNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            [
                              _c(
                                "a-form-model-item",
                                [
                                  _c(
                                    "a-space",
                                    [
                                      _c(
                                        "a-button",
                                        { on: { click: _vm.handleClear } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_clear")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loadingFind,
                                            icon: "search",
                                            "html-type": "submit"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_find")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table1",
                      "on-select-change": null,
                      "selected-row-keys": null,
                      "data-source": _vm.dtListStockCardReport,
                      columns: _vm.columnsTable,
                      scroll: { x: 1600 },
                      paginationcustom: true,
                      "default-pagination": false,
                      "handle-input": _vm.handleInput
                    },
                    on: { "on-columnClicked": _vm.handleClickedColumn },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(text, record) {
                          return _c(
                            "span",
                            { attrs: { scope: "onHandBefore" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toDecimalQty")(record.onHandBefore)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 18 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.totalData,
                      "page-size-set": _vm.paramReports.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 6, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "grey" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.totalData) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "download",
                            type: "primary",
                            loading: _vm.loadingDownload
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "printer",
                            type: "primary",
                            loading: _vm.loadingPrint
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            "mask-closable": false,
            keyboard: false,
            title: "Detail Unit Code"
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleOk },
          model: {
            value: _vm.modalVisible,
            callback: function($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible"
          }
        },
        [
          _c(
            "a-form",
            _vm._b(
              { attrs: { layout: "vertical", form: _vm.formModal } },
              "a-form",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.formRulesModal.merk.label } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: true,
                      name: _vm.formRulesModal.merk.name,
                      placeholder: _vm.formRulesModal.merk.placeholder
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.formRulesModal.type.label } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: true,
                      name: _vm.formRulesModal.type.name,
                      placeholder: _vm.formRulesModal.type.placeholder
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.formRulesModal.serialNumber.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRulesModal.serialNumber.decorator,
                        expression: "formRulesModal.serialNumber.decorator"
                      }
                    ],
                    attrs: {
                      disabled: true,
                      name: _vm.formRulesModal.serialNumber.name,
                      placeholder: _vm.formRulesModal.serialNumber.placeholder
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }