import { Api } from "@/models/class/api.class";
import { ResponseListProductCategory } from "@/models/interface-v2/product-category.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ProductCategoryServices extends HttpClient {
  constructor() {
    super()

    this.listProductCategory = this.listProductCategory.bind(this)
  }

  /**
   * get all product category
   * @param params query params
   * @returns list product category
   */
  listProductCategory(params: RequestQueryParamsModel): Promise<ResponseListProductCategory> {
    return this.get<ResponseListProductCategory>(Api.ProductCategory, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const productCategoryServices = new ProductCategoryServices()