import { Api } from "@/models/class/api.class";
import { RequestCreateWarehouse, ResponseCreateWarehouse, ResponseListWarehouse } from "@/models/interface-v2/warehouse.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class WarehouseService extends HttpClient {
  public constructor() {
    super()

    this.listWarehouse = this.listWarehouse.bind(this)
    this.createWarehouse = this.createWarehouse.bind(this)
    this.updateWarehouse = this.updateWarehouse.bind(this)
    this.deleteWarehouse = this.deleteWarehouse.bind(this)
  }

  public listWarehouse(params: RequestQueryParamsModel): Promise<ResponseListWarehouse> {
    return this.get<ResponseListWarehouse>(Api.Warehouse , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public createWarehouse(payload: RequestCreateWarehouse): Promise<ResponseCreateWarehouse> {
    return this.post<ResponseCreateWarehouse, RequestCreateWarehouse>(Api.Warehouse , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public updateWarehouse(payload: RequestCreateWarehouse , path:string): Promise<boolean> {
    return this.put<boolean, RequestCreateWarehouse>(`${Api.Warehouse}/${path}` , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public deleteWarehouse(path:string): Promise<boolean> {
    return this.delete<boolean>(`${Api.Warehouse}/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const warehouseService = new WarehouseService()