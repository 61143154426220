import { Api } from "@/models/class/api.class";
import { RequestProductUomCreate, ResponseListProductUom, ResponseProductUom, ResponseProductUomCreate } from "@/models/interface-v2/uom.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class MasterUomServices extends HttpClient {
  constructor() {
    super()

    this.listMasterUom = this.listMasterUom.bind(this)
    this.deleteMasterUom = this.deleteMasterUom.bind(this)
    this.createUom = this.createUom.bind(this)
    this.updateUom = this.updateUom.bind(this)
  }

  /**
   * get list all master product uom
   * @param params query params
   * @returns list uom
   */
  public listMasterUom(params : RequestQueryParamsModel): Promise<ResponseListProductUom> {
    return this.get<ResponseListProductUom>(Api.MasterUom, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  /**
   * delete uom by uomId
   * @param path uomId
   * @returns boolean
   */
   public deleteMasterUom(path:string): Promise<boolean> {
    return this.delete<boolean>(`${Api.MasterUom}/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  /**
   * create new UOM
   * @param payload request body create uom
   * @returns response product uom
   */
  public createUom(payload: RequestProductUomCreate): Promise<ResponseProductUomCreate> {
    return this.post<ResponseProductUomCreate, RequestProductUomCreate>(Api.MasterUom , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  /**
   * update existing uom by uomId
   * request body is same as create payload
   * @param payload request body product uom create
   * @param path uomId
   * @returns response product uom
   */
  public updateUom(payload: RequestProductUomCreate , path:string): Promise<ResponseProductUom> {
    return this.put<ResponseProductUom, RequestProductUomCreate>(`${Api.MasterUom}/${path}` , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
  
}

export const masterUomServices = new MasterUomServices()
